import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import blog1 from "../../../Assist/Blogs/blog1.jpeg";
import BlogData from "../BlogData";
import "../BlogPage.css";

const HowArdraAIImprovedEnergyEfficiency = () => {
  const { title } = useParams();
  const category = "Case-study";
  const readTime = "2 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    { id: "section1", title: "The Challenge" },
    { id: "section2", title: "The Solution" },
    { id: "section3", title: "Key Actions Taken" },
    { id: "section4", title: "The Results" },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [4, 2, 6].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img src={blog1} alt="Future of Energy Management" />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{category}</p>
          <h2>
            Case Study: How Ardra AI Improved Energy Efficiency for a Leading
            Manufacturer
          </h2>
          <p className="main-p">
            A leading manufacturing company faced challenges with high energy
            costs and inefficient energy use across its multiple production
            facilities. With rising operational costs and growing sustainability
            concerns, the company sought a comprehensive solution to optimize
            energy consumption and reduce waste. They turned to Ardra AI for its
            advanced, AI-driven energy management solutions.
          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <h3>The Challenge</h3>
              <p>
                The manufacturer struggled with fluctuating energy demands,
                inefficient equipment usage, and lack of real-time visibility
                into energy consumption patterns. These factors led to
                unnecessary energy expenses and increased carbon emissions.
              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>The Solution</h3>

              <p>
                Ardra AI deployed a suite of solutions designed to address these
                issues. Using IoT sensors and smart meters, Ardra AI enabled
                real-time monitoring of energy use across all production lines.
                This granular data was fed into Ardra AI's machine learning
                algorithms, which provided predictive insights into energy
                demand and identified opportunities for optimization.
              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <h3>Key Actions Taken</h3>
              <br />
              <p>
                <b>1. Predictive Maintenance: </b>
                Ardra AI's algorithms detected potential equipment failures
                before they occurred, allowing for timely maintenance. This
                proactive approach reduced downtime and prevented energy spikes
                associated with faulty machinery.
              </p>
              <p>
                <b>2. Dynamic Load Management: </b>
                The AI system adjusted energy loads based on real-time demand
                and production schedules, minimizing energy usage during peak
                periods and lowering costs.
              </p>
              <p>
                <b>3. Process Optimization: </b>
                By analyzing data from various systems, Ardra AI suggested
                changes to optimize HVAC and lighting systems, improving overall
                energy efficiency without affecting production quality.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>The Results</h3>
              <p>
              Within the first six months, the manufacturer achieved a 25% reduction in energy costs and a 30% decrease in energy waste. Additionally, the company significantly lowered its carbon footprint, aligning with its sustainability goals.
This case study demonstrates how Ardra AI's innovative solutions can transform energy management, helping companies achieve operational efficiency and substantial cost savings while contributing to a sustainable future.

              </p>
              
            </div>
          </div>
        </div>
      </div>

      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">{item.category}</p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowArdraAIImprovedEnergyEfficiency;
