import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";
import "./App.css";

import Layout from "./Component/Layout";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs";
import Career from "./Pages/Career";
import Document from "./Component/Document";
import Partners from "./Component/Partners";

import EmsPage from "./Pages/EmsPage";
import ProbePage from "./Pages/ProbePage";
import EnergyGPTPage from "./Pages/EnergyGPTPage";

import Team from "./Component/Team";

import Faq from "./Component/Faq";
import Testimonials from "./Component/Testimonials/testimonial";
import ComparisonTable from "./Component/ComparisonTable/comparison";
import ScrollToTop from "./Component/ScrollToTop";

import BlogPage from "./Component/Blog/BlogPage";
import BlogArticles from "./Component/Blog/BlogArticles";
import BlogCaseStudies from "./Component/Blog/BlogCaseStudies";
import BlogProductSpotlight from "./Component/Blog/BlogProductSpotlight";

import TheFutureOfEnergyManagement from "./Component/Blog/ArticleDetail_blog/The-Future-of-Energy-Management";
import UnderstandingEnergyManagementSystems from "./Component/Blog/ArticleDetail_blog/Understanding-Energy-Management-Systems";
import HowArdraAIImprovedEnergyEfficiency from "./Component/Blog/Case-studyDetail_blog/How-Ardra-AI-Improved-Energy-Efficiency";
import TheRoleofPredictiveAnalytics from "./Component/Blog/ArticleDetail_blog/The-Role-of-Predictive-Analytics";
import BeginnersGuidetoSmartGrids from "./Component/Blog/ArticleDetail_blog/A-Beginner's-Guide-to-Smart-Grids";
import WaysArdraAICanHelpReduceEnergyCosts from "./Component/Blog/Product-spotlightDetail_blog/Ways-Ardra-AI-Can-Help-Reduce-Energy-Costs";
import LeveragingMachineLearning from "./Component/Blog/ArticleDetail_blog/Leveraging-Machine-Learning";
import TopTrendsinEnergyManagement from "./Component/Blog/ArticleDetail_blog/Top-Trends-in-Energy-Management";
import ArdraONEARevolutionaryApproach from "./Component/Blog/Product-spotlightDetail_blog/Ardra-ONE-A-Revolutionary-Approach";
import HowIoTandAIAreShapingtheFuture from "./Component/Blog/ArticleDetail_blog/How-IoT-and-AI-Are-Shaping-the-Future";
import SustainabilityGoals from "./Component/Blog/ArticleDetail_blog/Sustainability-Goals";
import HowdoesAIdrivenpredictivemaintenancework from "./Component/Blog/ArticleDetail_blog/How-does-AI-driven-predictive-maintenance-work";
import ImprovingEnergyEfficiency from "./Component/Blog/Case-studyDetail_blog/Improving-Energy-Efficiency";
import OptimizingEnergyUse from "./Component/Blog/Case-studyDetail_blog/Optimizing-Energy-Use";
import TransformingEnergyEfficiency from "./Component/Blog/Case-studyDetail_blog/Transforming-Energy-Efficiency";
import StreamliningEnergyManagementforaUniversity from "./Component/Blog/Case-studyDetail_blog/Streamlining-Energy-Management-for-a-University";


// Google Analytics initialization
ReactGA.initialize("G-V5MB7S9LGD");

const AnalyticsTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AnalyticsTracker /> {/* Add AnalyticsTracker here */}

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="career" element={<Career />} />
          <Route path="document" element={<Document />} />
          <Route path="partner" element={<Partners />} />
          <Route path="emsPage" element={<EmsPage />} />
          <Route path="energyGPTPage" element={<EnergyGPTPage />} />
          <Route path="probe" element={<ProbePage />} />
          <Route path="team" element={<Team />} />
          <Route path="faq" element={<Faq />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="comparison" element={<ComparisonTable />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="/blog/articles" element={<BlogArticles />} />
          <Route path="/blog/case-studies" element={<BlogCaseStudies />} />
          <Route
            path="/blog/product-spotlight"
            element={<BlogProductSpotlight />}
          />
          <Route
            path="/ArticleDetail_blog/The-Future-of-Energy-Management"
            element={<TheFutureOfEnergyManagement />}
          />
          <Route
            path="/ArticleDetail_blog/Understanding-Energy-Management-Systems"
            element={<UnderstandingEnergyManagementSystems />}
          />
          <Route
            path="/Case-studyDetail_blog/How-Ardra-AI-Improved-Energy-Efficiency"
            element={<HowArdraAIImprovedEnergyEfficiency />}
          />
          <Route
            path="/ArticleDetail_blog/The-Role-of-Predictive-Analytics"
            element={<TheRoleofPredictiveAnalytics />}
          />
          <Route
            path="/ArticleDetail_blog/A-Beginner's-Guide-to-Smart-Grids"
            element={<BeginnersGuidetoSmartGrids />}
          />
          <Route
            path="/Product-spotlightDetail_blog/Ways-Ardra-AI-Can-Help-Reduce-Energy-Costs"
            element={<WaysArdraAICanHelpReduceEnergyCosts />}
          />
          <Route
            path="/ArticleDetail_blog/Leveraging-Machine-Learning"
            element={<LeveragingMachineLearning />}
          />
          <Route
            path="/ArticleDetail_blog/Top-Trends-in-Energy-Management"
            element={<TopTrendsinEnergyManagement />}
          />
          <Route
            path="/Product-spotlightDetail_blog/Ardra-ONE-A-Revolutionary-Approach"
            element={<ArdraONEARevolutionaryApproach />}
          />
          <Route
            path="/ArticleDetail_blog/How-IoT-and-AI-Are-Shaping-the-Future"
            element={<HowIoTandAIAreShapingtheFuture />}
          />
          <Route
            path="/ArticleDetail_blog/Sustainability-Goals"
            element={<SustainabilityGoals />}
          />
          <Route
            path="/ArticleDetail_blog/How-does-AI-driven-predictive-maintenance-work"
            element={<HowdoesAIdrivenpredictivemaintenancework />}
          />
          <Route
            path="/Case-studyDetail_blog/Improving-Energy-Efficiency"
            element={<ImprovingEnergyEfficiency />}
          />
          <Route
            path="/Case-studyDetail_blog/Optimizing-Energy-Use"
            element={<OptimizingEnergyUse />}
          />
          <Route
            path="/Case-studyDetail_blog/Transforming-Energy-Efficiency"
            element={<TransformingEnergyEfficiency />}
          />
          <Route path="/Case-studyDetail_blog/Streamlining-Energy-Management-for-a-University" element={<StreamliningEnergyManagementforaUniversity />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

//  <Team/>
// <Slider/>
// <UseCases/>
// <EnergyGPTPage/>
// <ProbePage/>
// <EmsPage/>
// <EnergyGpt/>
// <Ems/>
// <Probe/>
// <AboutUs/>
//  <Feature/>
// <Home_Header/>
// <SignInButton/>
// <BookDemoButton/>
// <SmallDeviseMenu/>
// <Circleanimate/>
// <AboutUs/>
// <Chatbot/>
// <Faq/>
// <BookDemoButton/>

{
  /* <Route path="/" element={<NavLayout />}>
        
      </Route> */
}

//       import Home_Header from './Component/Home_Header';
// import SignInButton from './Component/SignIn';
// import BookDemoButton from './Component/BookADemo';
// import UseCases from './Component/UseCases';
// import SmallDeviseMenu from './Component/Product';
// import Circleanimate from './Component/Circleanimate';
// import Chatbot from './Component/chatboat';
// import NavLayout from './Component/NavLayout';
