import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import blog1 from "../../../Assist/Blogs/blog1.jpeg";
import BlogData from "../BlogData";
import "../BlogPage.css";

const ArdraONEARevolutionaryApproach = () => {
  const { title } = useParams();
  const category = "Product-spotlight";
  const readTime = "2 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    {
      id: "section1",
      title: "Key Features of Ardra ONE’s Edge Hardware:",
    },
    { id: "section2", title: "Advantages of Ardra ONE’s Edge Hardware in Energy Management:" },
   
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [4, 2, 6].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img src={blog1} alt="Future of Energy Management" />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{category}</p>
          <h2>Ardra ONE: A Revolutionary Approach to Energy Management with Edge Hardware</h2>
          <p className="main-p">
          Ardra ONE represents a cutting-edge approach to energy management, leveraging advanced edge hardware to provide real-time, reliable, and efficient energy solutions. By integrating powerful ARM controllers and the LoRaWAN network, Ardra ONE redefines how businesses monitor, manage, and optimize their energy consumption.

          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
             
              <h3>Key Features of Ardra ONE’s Edge Hardware</h3>
              <br />
              <h4>1. ARM Controllers</h4>
              <p>
              At the heart of Ardra ONE's hardware are ARM controllers, known for their low power consumption, high processing speed, and robust performance. These controllers enable real-time data processing and analysis at the edge, reducing the need for constant cloud communication. This results in faster response times, lower latency, and more reliable energy management, especially in remote or bandwidth-constrained environments.

              </p>
             <h4>2. LoRaWAN Network Integration</h4>
             <p>Ardra ONE integrates with the LoRaWAN (Long Range Wide Area Network) protocol, providing long-range communication capabilities with low power consumption. This makes it ideal for connecting IoT devices and sensors across large industrial facilities, smart buildings, or campuses. The network ensures reliable data transmission over vast areas, enabling seamless monitoring and control of energy assets.
             </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>Advantages of Ardra ONE’s Edge Hardware in Energy Management
              </h3>
              <br />
              <p>
                <b>● Real-Time Decision-Making: </b>
                By processing data at the edge, Ardra ONE can make real-time adjustments to energy consumption and equipment settings, optimizing energy use without delays. This is crucial for dynamic environments where rapid decision-making is essential for maintaining efficiency.
              </p>
              <p>
                <b>● Reduced Operational Costs: </b>
                The low-power nature of ARM controllers and efficient communication through LoRaWAN reduce operational costs, making Ardra ONE a cost-effective solution for energy management.

              </p>
              <p>
                <b>● Enhanced Security and Reliability: </b>
                Keeping data at the edge minimizes the risk of data breaches associated with cloud transmission. Ardra ONE’s edge hardware provides robust encryption and secure data handling, ensuring that sensitive information remains protected.

              </p>
              <p>
                <b>● Scalability and Flexibility: </b>
                The modular design of Ardra ONE's hardware allows businesses to scale their energy management systems easily, accommodating new devices, sensors, or zones without significant changes to the infrastructure.

              </p>
              <br />
              <p>Ardra ONE's innovative edge hardware solution delivers a powerful, secure, and cost-effective approach to energy management, helping organizations optimize energy use, reduce waste, and achieve sustainability goals in a rapidly evolving energy landscape.
              </p>
            </div>

           
          </div>
        </div>
      </div>

      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
               <p className="blog-item-category">
                {item.type ? item.type : item.category}
              </p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArdraONEARevolutionaryApproach;
