import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import BlogData from "../BlogData";
import "../BlogPage.css";
import ABeginnersGuidetoSmartGridsandTheirBenefitsinner from "../../../Assist/Blogs/A-Beginners-Guide-to-Smart-Grids-and-Their-Benefits-inner.png";
import ABeginnersGuidetoSmartGridsandTheirBenefitsmain from "../../../Assist/Blogs/A-Beginners-Guide-to-Smart-Grids-and-Their-Benefits-main.jpg";
import ShareBlog from "../share_blog/share_blog";
import { Helmet } from "react-helmet";

const BeginnersGuidetoSmartGrids = () => {
  const { title } = useParams();
  const category = "Guide Article";
  const readTime = "3 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    { id: "section1", title: "What is a Smart Grid?" },
    { id: "section2", title: "Key Components of a Smart Grid" },
    { id: "section3", title: "Benefits of Smart Grids" },
    { id: "section4", title: "Ardra AI: Driving the Smart Grid Revolution" },
    { id: "section5", title: "Conclusion" },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [7, 6, 16].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150;

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <Helmet>
        <title>A Beginner's Guide to Smart Grids and Their Benefits</title>
        <meta
          name="description"
          content="Explore the fundamentals of smart grids and their benefits in energy management. Discover how Ardra AI is leading the smart grid revolution."
        />
        <meta
          name="keywords"
          content="Smart Grids, Energy Management, Smart Grid Benefits, Renewable Energy Integration, Advanced Metering Infrastructure (AMI), Automated Control Systems, Artificial Intelligence in Energy, Energy Efficiency, IoT in Energy, Electricity Grid Modernization, Sustainable Energy Solutions, Smart Energy Grids, Digital Energy Networks, AI in Smart Grids, Future of Energy Management, Energy Optimization, Smart Grid Technology, Energy Monitoring Systems, Grid Automation, Real-time Energy Data."
        />
        <meta name="author" content="Ardra AI" />
      </Helmet>

      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img
            src={ABeginnersGuidetoSmartGridsandTheirBenefitsmain}
            alt="Benefits of Smart Grids"
          />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{category}</p>
          <h2>A Beginner's Guide to Smart Grids and Their Benefits</h2>
          <p className="main-p">
            In today's rapidly evolving energy landscape, smart grids are
            emerging as a game-changing solution to meet the growing demand for
            reliable, efficient, and sustainable electricity. As the world
            becomes increasingly digitized, the integration of advanced
            technologies like the Internet of Things (IoT) and Artificial
            Intelligence (AI) is revolutionizing the way we generate,
            distribute, and consume energy. At the forefront of this
            transformation is Ardra AI, a company dedicated to providing
            innovative solutions that harness the power of smart grids to
            optimize energy management.
          </p>
          <p className="blog-author">
            <b>Author: </b>Smriti Yadav
            <p className="blog-date">30th Sep 2024</p>
          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault();
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <h3>What is a Smart Grid?</h3>
              <p>
                A smart grid is an intelligent electricity network that utilizes
                digital technologies to enhance the efficiency, reliability, and
                sustainability of power generation and distribution. Unlike
                traditional grids, smart grids enable two-way communication
                between utilities and consumers, allowing for real-time
                monitoring, control, and optimization of energy usage.
              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>Key Components of a Smart Grid</h3>
              <br />
              <h4>1. Advanced Metering Infrastructure (AMI)</h4>
              <p>
                Smart meters and sensors collect real-time data on energy
                consumption, enabling utilities to monitor usage patterns and
                identify potential inefficiencies.
              </p>
              <h4>2. Automated Control Systems</h4>
              <p>
                AI-driven algorithms dynamically adjust energy-intensive
                operations, such as HVAC systems and lighting, based on
                real-time data and predictive models, ensuring optimal
                efficiency.
              </p>
              <h4>3. Renewable Energy Integration</h4>
              <p>
                Smart grids facilitate the seamless integration of renewable
                energy sources like solar and wind power, balancing supply and
                demand to ensure a steady flow of clean energy.
              </p>
              <h4>4. Energy Storage Systems</h4>
              <p>
                IoT-connected batteries and energy storage solutions play a
                crucial role in storing excess renewable energy, optimizing
                energy release, and even enabling grid sales.
              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <img
                src={ABeginnersGuidetoSmartGridsandTheirBenefitsinner}
                alt="Benefits of Smart Grids"
                className="innerimage"
                style={{ width: "100%" }}
              />
              <br />
              <br />
              <br />
              <h3>Benefits of Smart Grids</h3>
              <br />
              <h4>1. Enhanced Efficiency</h4>
              <p>
                Smart grids reduce energy waste and optimize resource
                allocation, leading to significant cost savings for both
                utilities and consumers.
              </p>
              <h4>2. Improved Reliability</h4>
              <p>
                Advanced sensors and automation technologies enable smart grids
                to detect and respond to outages quickly, minimizing the
                frequency and duration of power disruptions.
              </p>
              <h4>3. Increased Sustainability</h4>
              <p>
                By integrating renewable energy sources and promoting energy
                efficiency, smart grids contribute to a greener, more
                sustainable future.
              </p>
              <h4>4. Consumer Empowerment</h4>
              <p>
                Smart grids provide consumers with real-time data on their
                energy usage, enabling them to make informed decisions about
                their consumption habits and potentially save money on their
                energy bills.
              </p>
              <h4>5. Reduced Carbon Emissions</h4>
              <p>
                According to the International Energy Agency, smart grids have
                the potential to reduce carbon emissions from the electricity
                sector by up to 30% by 2030, saving an estimated 34,000 lives
                annually.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>Ardra AI: Driving the Smart Grid Revolution</h3>
              <p>
                Ardra AI is at the forefront of the smart grid revolution,
                providing AI-driven solutions that optimize energy management
                for businesses and utilities. By leveraging the power of IoT and
                machine learning, Ardra AI's solutions enable real-time
                monitoring, predictive maintenance, and automated control of
                energy systems.
              </p>
              <p>
                One of Ardra AI's key offerings is its Edge Hardware solution,
                which integrates powerful ARM controllers and the LoRaWAN
                network to deliver reliable, efficient, and cost-effective
                energy management. This innovative approach processes data at
                the edge, reducing the need for constant cloud communication and
                ensuring faster response times, lower latency, and more reliable
                energy management, especially in remote or bandwidth-constrained
                environments.
              </p>
            </div>

            <div
              id="section5"
              ref={el => (sections.current[4] = el)}
              className="content-section"
            >
              <h3>Conclusion</h3>
              <p>
                As the world continues to embrace the digital revolution, smart
                grids are poised to play a crucial role in shaping the future of
                energy management. By harnessing the power of advanced
                technologies like IoT and AI, smart grids offer a comprehensive
                solution to the challenges of energy efficiency, reliability,
                and sustainability. With Ardra AI leading the charge, businesses
                and utilities can take advantage of cutting-edge smart grid
                solutions to optimize their energy usage, reduce costs, and
                contribute to a greener, more sustainable future.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ShareBlog />
      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">
                {item.type ? item.type : item.category}
              </p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BeginnersGuidetoSmartGrids;
