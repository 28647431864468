import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import BlogData from "../BlogData";
import "../BlogPage.css";
import WaysArdraAICanHelpReduceEnergyCostsmain from "../../../Assist/Blogs/Ways-Ardra-AI-Can-Help-Reduce-Energy-Costs-main.jpg";
import ShareBlog from "../share_blog/share_blog";
import { Helmet } from "react-helmet";

const WaysArdraAICanHelpReduceEnergyCosts = () => {
  const { title } = useParams();
  const category = "Product-spotlight";
  const readTime = "2 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    {
      id: "section1",
      title: "1. Real-Time Energy Monitoring and Optimization",
    },
    { id: "section2", title: "2. Predictive Maintenance" },
    { id: "section3", title: "3. Smart Load Management" },
    { id: "section4", title: "4. Energy Forecasting for Better Planning" },
    { id: "section5", title: "5. Sustainability and Compliance" },
    { id: "section6", title: "Why Choose Ardra AI?" },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [15, 4,5].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <Helmet>
        <title>5 Ways Ardra AI Can Help Reduce Energy Costs</title>
        <meta
          name="description"
          content="Learn how Ardra AI's platform can help reduce energy costs in manufacturing through real-time monitoring, predictive maintenance, smart load management, and more."
        />
        <meta
          name="keywords"
          content="Ardra AI, Reduce Energy Costs, Energy Management, AI in Manufacturing, Smart Load Management, Predictive Maintenance, Energy Optimization, Ardra AI energy management platform, Ardra AI energy cost reduction solutions, Ardra AI for manufacturing efficiency"
        />
        <meta
          property="og:title"
          content="5 Ways Ardra AI Can Help Reduce Energy Costs"
        />
        <meta
          property="og:description"
          content="Explore how Ardra AI uses advanced technology to optimize energy usage, enhance efficiency, and drive sustainability in manufacturing."
        />
        <meta
          property="og:image"
          content={WaysArdraAICanHelpReduceEnergyCostsmain}
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="5 Ways Ardra AI Can Help Reduce Energy Costs"
        />
        <meta
          name="twitter:description"
          content="Discover how Ardra AI helps businesses reduce energy costs through cutting-edge AI technology."
        />
        <meta
          name="twitter:image"
          content={WaysArdraAICanHelpReduceEnergyCostsmain}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img
            src={WaysArdraAICanHelpReduceEnergyCostsmain}
            alt="Ways Ardra AI Can Help Reduce Energy Costs"
          />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{category}</p>
          <h2>5 Ways Ardra AI Can Help Reduce Energy Costs in Manufacturing</h2>
          <p className="main-p">
            Ardra AI's platform enhances manufacturing efficiency by providing
            real-time energy monitoring, predictive maintenance, and smart load
            management. This leads to reduced energy costs, minimized downtime,
            and improved sustainability.
          </p>
          <p className="blog-author">
            By the team at Ardra AI
            <p className="blog-date"> 15th August 2024</p>
          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <p>
                In today's competitive manufacturing landscape, reducing energy
                costs isn't just about saving money—it's about staying ahead. At
                Ardra AI, we understand the unique challenges of the
                manufacturing sector and have developed a cutting-edge AI-driven
                platform designed to optimize energy usage, enhance efficiency,
                and drive sustainability. Here’s how Ardra AI can make a
                tangible difference for your business:
              </p>
              <br />
              <h3>1. Real-Time Energy Monitoring and Optimization</h3>
              <p>
                Our platform provides real-time visibility into your energy
                consumption across all operations. By continuously monitoring
                and analyzing data, Ardra AI identifies inefficiencies and
                offers instant recommendations to optimize energy use, ensuring
                you always operate at peak efficiency.
              </p>
              <p>
                <b>Benefit: </b>
                Reduce energy waste and cut costs by up to 20% with actionable
                insights delivered directly to your team.
              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>2. Predictive Maintenance</h3>
              <p>
                Equipment failures and downtime can be costly—not just in terms
                of repairs, but also in energy waste. Ardra AI uses predictive
                analytics to foresee equipment issues before they occur. By
                scheduling maintenance at the optimal time, you can prevent
                unexpected breakdowns and keep your machinery running
                efficiently.
              </p>
              <p>
                <b>Benefit:</b>
                Minimize downtime, extend equipment life, and reduce unnecessary
                energy consumption.
              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <h3>3. Smart Load Management</h3>
              <p>
                Fluctuations in energy demand can lead to inefficiencies and
                higher costs. Ardra AI's smart load management feature
                dynamically adjusts energy distribution based on real-time data,
                ensuring that energy is used where it’s needed most, when it’s
                needed most.
              </p>
              <p>
                <b>Benefit: </b>
                Avoid energy spikes and optimize load distribution to reduce
                peak energy charges.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>4. Energy Forecasting for Better Planning</h3>
              <p>
                Planning is key to efficient energy management. Ardra AI
                leverages advanced forecasting models to predict your future
                energy needs with precision. By anticipating demand, you can
                make informed decisions about energy procurement and production
                schedules, avoiding costly overuse or underutilization of
                resources.
              </p>
              <p>
                <b>Benefit:</b>
                Improve energy procurement strategies and align production
                schedules with energy availability, maximizing efficiency.
              </p>
            </div>

            <div
              id="section5"
              ref={el => (sections.current[4] = el)}
              className="content-section"
            >
              <h3>5. Sustainability and Compliance</h3>
              <p>
                Energy efficiency isn’t just about cutting costs—it’s about
                meeting sustainability goals and regulatory requirements. Ardra
                AI helps you track and reduce your carbon footprint by
                optimizing energy usage and integrating renewable energy sources
                when possible. Our platform also ensures compliance with
                industry regulations, giving you peace of mind.
              </p>
              <p>
                <b>Benefit:</b>
                Enhance your brand’s sustainability credentials and ensure
                compliance with energy regulations, all while reducing costs.
              </p>
            </div>

            <div
              id="section6"
              ref={el => (sections.current[5] = el)}
              className="content-section"
            >
              <h3>Why Choose Ardra AI?</h3>
              <p>
                Ardra AI is not just a product—it's a comprehensive solution for
                manufacturing companies looking to innovate and stay
                competitive. By combining real-time data analytics, predictive
                maintenance, and smart energy management, our platform is
                designed to deliver measurable results.
              </p>
              <p>
                Ready to transform your energy management strategy?{" "}
                <b>Contact Ardra AI today</b> and start reducing your energy
                costs while driving efficiency and sustainability in your
                operations.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ShareBlog />
      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
               <p className="blog-item-category">
                {item.type ? item.type : item.category}
              </p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WaysArdraAICanHelpReduceEnergyCosts;
