// Pagination.js
import React, { useEffect } from "react";
import "./Pagination.css"; 

const Pagination = ({ currentPage, totalPages, onPageChange }) => {

  const createPaginationRange = () => {
    const range = [];
    const delta = 2; // Number of pages to show around the current page

    for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
      range.push(i);
    }

    // Add ellipses for ranges with more than one page difference
    if (currentPage - delta > 2) {
      range.unshift("...");
    }
    if (currentPage + delta < totalPages - 1) {
      range.push("...");
    }

    range.unshift(1); 
    if (totalPages > 1) range.push(totalPages); 

    return range;
  };

  const paginationRange = createPaginationRange();

  // Scroll to top 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);  

  return (
    <div className="pagination">
      {/* Previous Button */}
      <button
        className="pagination-button"
        onClick={() => {
          if (currentPage > 1) onPageChange(currentPage - 1);
        }}
        disabled={currentPage === 1}
      >
        <i className="fa-solid fa-arrow-left fa-beat-fade"></i>{/* Left Arrow */}
      </button>

      {/* Pagination Range */}
      {paginationRange.map((page, index) => (
        <button
          key={index}
          className={`pagination-button ${
            currentPage === page ? "active" : ""
          }`}
          onClick={() => onPageChange(page)}
          disabled={page === "..."}
        >
          {page}
        </button>
      ))}

      {/* Next Button */}
      <button
        className="pagination-button"
        onClick={() => {
          if (currentPage < totalPages) onPageChange(currentPage + 1);
        }}
        disabled={currentPage === totalPages}
      >
        <i className="fa-solid fa-arrow-right fa-beat-fade"></i>{/* Right Arrow */}
      </button>
    </div>
  );
};

export default Pagination;
