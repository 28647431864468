import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import blog1 from "../../../Assist/Blogs/blog1.jpeg";
import BlogData from "../BlogData";
import "../BlogPage.css";

const OptimizingEnergyUse = () => {
  const { title } = useParams();
  const category = "Case-study";
  const readTime = "2 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    { id: "section1", title: "The Challenge" },
    { id: "section2", title: "The Solution" },
    { id: "section3", title: "Key Actions Taken" },
    { id: "section4", title: "The Results" },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [4, 2, 6].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img src={blog1} alt="Future of Energy Management" />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{category}</p>
          <h2>
            Case Study: Optimizing Energy Use in a Smart Building Complex with Ardra AI
          </h2>
          <p className="main-p">
          This case study highlights how Ardra AI’s solutions were implemented in a modern smart building complex to achieve optimal energy efficiency and reduce overall energy costs.

          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <h3>The Challenge</h3>
              <p>
              The smart building complex consisted of multiple residential and commercial units, each with varying energy needs. The management faced challenges in monitoring and balancing energy consumption effectively across units, leading to high energy bills and resource inefficiencies.

              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>The Solution</h3>

              <p>
              Ardra AI deployed its AI-powered energy management system to provide real-time monitoring, predictive analytics, and energy optimization across the entire complex. The solution involved integrating smart meters, IoT sensors, and edge hardware for local data processing, enhancing responsiveness and reducing latency.

              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <h3>Key Actions Taken</h3>
              <br />
              <p>
                <b>1. Real-Time Energy Monitoring: </b>
                Enabled comprehensive visibility into energy consumption patterns for each unit and the overall complex.

              </p>
              <p>
                <b>2. Smart Load Balancing: </b>
                Utilized AI algorithms to balance energy loads dynamically based on real-time demand, reducing energy waste.

              </p>
              <p>
                <b>3. Automated Energy Control: </b>
                Implemented automated controls for lighting, HVAC, and other systems to optimize energy usage based on occupancy and weather conditions.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>The Results</h3>
              <p>
                <b>● 25% Reduction in Overall Energy Costs: </b>
                Achieved within the first six months.
              </p>
              <p>
                <b>● Enhanced Occupant Comfort: </b>
                By maintaining optimal indoor environments with automated energy controls.

              </p>
              <p>
                <b>● Improved Energy Management Efficiency: </b>
                With actionable insights and predictive analytics.

              </p>
            </div>
            <div
              id="section5"
              ref={el => (sections.current[4] = el)}
              className="content-section"
            >
              <h3>Conclusion</h3>
              <p>
              Ardra AI’s smart energy management solutions enabled the building complex to achieve sustainable energy usage, reduce costs, and enhance tenant satisfaction. The case study illustrates the value of AI-driven energy management in modern smart buildings.

              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">{item.category}</p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OptimizingEnergyUse;
